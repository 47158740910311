<template>
  <div class="py-2 mt-3">
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th width="40%">กลุ่ม</th>
          <th width="15%" class="text-center">ได้รับ</th>
          <th width="15%" class="text-center">แบ่งหุ้น(ให้เอเย่นต์)</th>
          <th width="15%" class="text-center">เก็บของ</th>
          <th class="text-center">เก็บของเกิน</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="group in data" v-if="group.isAvailable">
          <td>{{ groupName(group.groupId) }}</td>
          <td class="text-center">
            <b-input-group size="sm" append="%">
              <b-form-input value="100" type="number" min="0" max="100" disabled></b-form-input>
            </b-input-group>
          </td>
          <td class="text-center">
            <b-input-group size="sm" append="%">
              <b-form-input v-model="group.share.receive" type="number" min="0" max="100" disabled></b-form-input>
            </b-input-group>
          </td>
          <td class="text-center">
            <b-input-group size="sm" append="%">
              <b-form-input v-model="group.share.referral" disabled></b-form-input>
            </b-input-group>
          </td>
          <td class="text-center">
            <b-form-checkbox v-model="group.share.over" name="check-button" :class="[group.share.over ? 'text-success' : 'text-danger']" switch disabled> <span class="small">{{ group.share.over ? 'เก็บของเกิน' : 'ไม่เก็บของเกิน' }}</span> </b-form-checkbox>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'TabModalShare',
  props: ['data'],
  methods: {
    groupName(groupId) {
      let group = this.$store.state.marketGroups.find((g)=>{
        return g._id === groupId
      })

      if(group) {
        return group.groupTitle
      }else{
        return groupId
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  th {
    border-top: 0;
  }
  td {
    vertical-align: middle;
  }
}
</style>
